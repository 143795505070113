import * as React from 'react';
import {useEffect} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'

import './table.module.css';
import AIAvatar from './ai-icon-7.jpg';
import BouncingDotsProgress from './BouncingDotsProgress';

type ChatHistoryProps = {
  messages: string[];
  userAvatar?: string;
  userName?: string;
  loading?: boolean;
}
export default function ChatHistory({messages, userAvatar, userName, loading}: ChatHistoryProps) {
  const scrollRef = React.useRef<HTMLLIElement>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages.length]);
  return (
    <List
      sx={{
        minHeight: 48,
        bgcolor: 'background.paper',
        overflowY: 'auto'
      }}
    >
      {/* pre loading avatars */}
      {messages.length === 0 && (
        <>
          <Avatar src={userAvatar} sx={{display: 'none'}}/>
          <Avatar src={AIAvatar} sx={{display: 'none'}}/>
        </>
      )}
      {messages.map((message, index) => {
        let messageText= "";
        let isAI = false;
        if (message.includes("AI:")) {
          messageText = message.replace("AI: ", "");
          isAI = true;
        } else if (message.includes("User: ")) {
          messageText = message.replace("User: ", "");
        } else {
          messageText = message;
        }

        return (
          <div className="chat-history-container" key={message.substring(0,20)}>
            {index > 0 && (<Divider variant="inset" component="li"/>)}
            <ListItem ref={scrollRef}>
              <ListItemAvatar sx={{paddingY: '6px', alignSelf: 'flex-start'}}>
                <Avatar alt={isAI ? "AI" : (userName || 'U')} src={isAI ? AIAvatar : userAvatar} />
              </ListItemAvatar>
              <ListItemText
                primary={<ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{messageText}</ReactMarkdown>}
                // primaryTypographyProps={{variant: 'body1'}}
              />
            </ListItem>
          </div>
        )
      })}
      {loading && (
        <>
          <Divider variant="inset" component="li" />
          <ListItem ref={scrollRef}>
            <ListItemAvatar sx={{paddingY: '6px', alignSelf: 'flex-start'}}>
              <Avatar alt="AI" src={AIAvatar}/>
            </ListItemAvatar>
            <BouncingDotsProgress />
          </ListItem>
        </>
      )}

    </List>
  );
}