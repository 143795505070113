import React, {useState} from "react";
import {User} from "@auth0/auth0-react";
import ChatHistory from "./ChatHistory";
import {Box} from "@mui/material";
import useWindowDimensions from "../hooks/useWindowDimensions";
import InputForm from "./InputForm";

type ChatBoxProps = {
  user: User | undefined;
}
function ChatBox({user}: ChatBoxProps) {
  const [messages, setMessages] = useState<string[]>([]);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const { height, width } = useWindowDimensions();

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const message = userInput.trim();
    if (message === "") return;

    const tmpMessages = [...messages, `User: ${message}`];

    setMessages(tmpMessages);
    setUserInput("");

    // Send request to the backend
    if (process.env.REACT_APP_API_URL) {
      try {
        event.preventDefault();
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user?.email,
            message: message,
            chat_history: tmpMessages,
          }),
        });

        if (response.ok) {
          event.preventDefault();
          const data = await response.json();
          const aiMessage = data.response.content;
          setMessages([...tmpMessages, `AI: ${aiMessage}`]);
        } else {
          console.error("Error sending message to the backend");
        }

      } catch (error) {
        console.error("Error occurred during fetch request:", error);
      }
    } else {
        console.error("REACT_APP_API_URL is not defined");
        alert("REACT_APP_API_URL is not defined")
    }
    setLoading(false);
  }
  return (
    <Box
      id="container"
      sx={{
          height: {
            xs: '100vh',
            sm: '100%',
          },
          maxHeight: {
            sm: '90vh',
          },
          width: {
            xs: '100vw',
            md: 720
          },
      }}
    >
      <Box
        id="chat-header"
        sx={{
          backgroundColor: '#007bff',
          color: 'white',
          fontSize: '1.1em',
          padding: {
            xs: '22px 50px',
            sm: '8px',
          },
          textAlign: 'center',
        }}
      >
        ChatNotes - try asking anything about PSY
      </Box>
      <Box
        id="chat-box"
        sx={{
          backgroundColor: 'white',
          border: '1px solid #e0e0e0',
          borderRadius: '5px',
          display: 'flex',

          flexDirection: 'column-reverse',
          height: 'calc(100% - 64px)',
          maxHeight: {
            xs: height - 64,
            sm: 'calc(90vh - 64px)',
          },
        }}
      >

        <InputForm onSubmit={handleSubmit} loading={loading} userInput={userInput} setUserInput={setUserInput} />
        <ChatHistory messages={messages} userAvatar={user?.picture} userName={user?.nickname} loading={loading}/>
      </Box>
    </Box>
  );
}

export default ChatBox;
