import React from "react";
import {Box, Button, InputBase} from "@mui/material";
type InputFormProps = {
  onSubmit: (event: React.SyntheticEvent) => void;
  loading: boolean;
  userInput: string;
  setUserInput: (value: string) => void;
}
function InputForm({onSubmit, userInput, setUserInput, loading}: InputFormProps) {

  return (
    <form id="chatForm" onSubmit={onSubmit}>
      <Box sx={{
        borderTop: '1px solid #e0e0e0',
        display: 'flex',
        padding: 2,
        alignItems: 'center',
      }}>
        <InputBase
          sx={{width: '100%', paddingX: 2}}
          id="userInput"
          placeholder="Type your message..."
          value={userInput}
          onChange={(event) => setUserInput(event.target.value)}
          multiline
          onKeyDown={(event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
              onSubmit(event);
            }
          }}
        />
        <Button
          type="submit"
          variant="contained"
          disableElevation
          disabled={loading}
          onClick={onSubmit}
          sx={{
            height: 36
          }}
        >
          Send
        </Button>

      </Box>
    </form>
  );
}

export default InputForm;
