import React from 'react';
import './style.css';

const BouncingDotsProgress = props => {
    return (
        <>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
};

export default BouncingDotsProgress;
