import * as React from 'react';
// import { useMediaQuery } from 'gatsby-theme-portfolio-minimal/src/hooks/useMediaQuery';
import useMediaQuery from '@mui/material/useMediaQuery';
import UserIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import {User} from "@auth0/auth0-react";

type UserAvatarProps = {
  user: User | undefined;
  loading: boolean;
}

export function UserAvatar({ user, loading }: UserAvatarProps) {
  const isDesktopBreakpoint = useMediaQuery('(min-width: 900px)');
  return user?.picture ? (
    <Avatar
      sx={{
        // backgroundColor: '#f7f7f8',
        width: isDesktopBreakpoint ? 52 : 44,
        height: isDesktopBreakpoint ? 52 : 44,
      }}
      src={user?.picture}
    >
      {/* fallback icon if avatar image load fails*/}
      <UserIcon
        sx={{
          fontSize: '2.3em',
          borderRadius: '20px',
        }}
      />
    </Avatar>
  ) : loading ? (
    <CircularProgress size={28} />
  ) : (
    <UserIcon
      sx={{
        fontSize: '2.3em',
        border: '3px solid #a36a2f',
        borderRadius: '20px',
      }}
    />
  );
}
