import React from 'react';
import logo from './logo.svg';
import './App.css';

import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import ChatBox from "./components/ChatBox";
import {Box, CircularProgress} from "@mui/material";
import UserButtonAndCard, {UserButtonAndCardDesktop, UserButtonAndCardMobile} from "./components/UserAvatarCard";
import useMediaQuery from "@mui/material/useMediaQuery";

function App() {
  const {user, error, isAuthenticated, loginWithRedirect, isLoading} = useAuth0();
  if (isLoading) {
    return <CircularProgress sx={{color: 'lightgray'}} size={64}/>;
  }
  if (error) {
    return <div>Oops... {error?.message}</div>;
  }
  if (!isAuthenticated) {
    loginWithRedirect();
    return <div>Loading...</div>;
  }
  if (isLoading) return (<div>Loading...</div>);

  return (
    <div className="App">
      <UserButtonAndCard />
      <ChatBox user={user} />
    </div>
  );
}

export default App;
