import * as React from 'react';
import Menu, { MenuActions } from '@mui/base/Menu';
import MenuItem, { menuItemClasses } from '@mui/base/MenuItem';
import { ListActionTypes } from '@mui/base/useList';
import { Popper } from '@mui/base';
import { Box, styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';

import { UserDetailsCard } from './components/UserDetailsCard';
import { UserAvatar } from './components/UserAvatar';
import {useAuth0} from "@auth0/auth0-react";
import {Button, IconButton, SwipeableDrawer} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";



const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 12px;
  margin: 12px 0;
  min-width: 160px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  `
);

const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: flex-end;

  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemClasses.focusVisible} {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${menuItemClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `
);

const TriggerButton = styled('button')(
  ({ theme }) => `
  display: flex;
  align-items: center;
  align-self: center;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  border-radius: 32px;
  padding: 8px 16px;
  // background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border: none;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  cursor: pointer;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:focus {
    border-color: ${blue[400]};
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }
  `
);

const StyledPopper = styled(Popper)`
  z-index: 100;
`;

export function UserButtonAndCardDesktop() {
  const [buttonElement, setButtonElement] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [isOpen, setOpen] = React.useState(false);
  const menuActions = React.useRef<MenuActions>(null);
  const preventReopen = React.useRef(false);
  // const userContext = useUserData();
  const { user, isLoading, logout } = useAuth0();

  const updateAnchor = React.useCallback((node: HTMLButtonElement | null) => {
    setButtonElement(node);
  }, []);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (preventReopen.current) {
      event.preventDefault();
      preventReopen.current = false;
      return;
    }

    setOpen((open) => !open);
  };

  const handleButtonMouseDown = () => {
    if (isOpen) {
      // Prevents the menu from reopening right after closing
      // when clicking the button.
      preventReopen.current = true;
    }
  };

  const handleButtonKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      setOpen(true);
      if (event.key === 'ArrowUp') {
        // Focus the last item when pressing ArrowUp.
        menuActions.current?.dispatch({
          type: ListActionTypes.keyDown,
          key: event.key,
          event,
        });
      }
    }
  };
  const createHandleMenuClick = (menuItem?: string) => {
    return () => {
      console.log(`Clicked on ${menuItem}`);
      // window.location.replace(menuItem);
      setOpen(false);
      buttonElement?.focus();
    };
  };



  return (
    <>
      <TriggerButton
        type="button"
        onClick={handleButtonClick}
        onKeyDown={handleButtonKeyDown}
        onMouseDown={handleButtonMouseDown}
        ref={updateAnchor}
        aria-controls={isOpen ? 'simple-menu' : undefined}
        aria-expanded={isOpen || undefined}
        aria-haspopup="menu"
      >
        <UserAvatar user={user} loading={isLoading} />
      </TriggerButton>
      <Menu
        actions={menuActions}
        open={isOpen}
        onOpenChange={(open) => {
          setOpen(open);
        }}
        anchorEl={buttonElement}
        slots={{ root: StyledPopper, listbox: StyledListbox }}
        slotProps={{ root: { placement: 'bottom-end' }, listbox: { id: 'simple-menu' } }}
      >
        <UserDetailsCard user={user} />
        <StyledMenuItem onClick={() => {
          createHandleMenuClick()
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            }
          });
        }}>
          Sign Out
        </StyledMenuItem>

      </Menu>
    </>
  );
}

export function UserButtonAndCardMobile() {
  const [open, setOpen] = React.useState(false);
  const { user, isLoading, logout } = useAuth0();

  const toggleDrawer =
    (isOpen: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event?.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(isOpen);
  }

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer(true)}
        edge="start"
        sx={{ zIndex: 1, ...(open && { display: 'none' }) }}
      >
        <MenuIcon fontSize="large"/>
      </IconButton>

      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          padding: '16px 24px',
        }}>
          <UserAvatar user={user} loading={isLoading} />
          <UserDetailsCard user={user} />

          <Button
            variant="text"
            style={{ fontSize: 18, fontWeight: 400 }}
            onClick={() => {
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                }
              });
            }}
          >
            Sign out
          </Button>
        </Box>

      </SwipeableDrawer>
    </>
  );
}

export default function UserButtonAndCard() {
  const smScreen = useMediaQuery('(max-width:599px)');

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '1%',
        right: '3%'
      }}>
      {smScreen ? (
          <UserButtonAndCardMobile />
        ) : (
          <UserButtonAndCardDesktop />
        )
      }
    </Box>
  )
}