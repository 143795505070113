import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/system';

type Props = {
  user: any;
};

const StyledListItem = styled(ListItem)`
  justify-content: flex-end;
  text-align: right;

  &.MuiListItem-gutters {
    padding-left: 40px;
    padding-right: 4px;
  }
`;

export function UserDetailsCard({ user }: Props) {
  const primaryText = user.given_name && user.family_name ? `${user.given_name} ${user.family_name}` : user.nickname;
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        // backgroundColor: theme.palette.background.paper,
      }}
    >
      <StyledListItem>
        {user ? (
          <ListItemText
            sx={{
              '& .MuiListItemText-primary': {
                fontWeight: 'bold',
              },
              '& .MuiListItemText-secondary': {
                // fontSize: '0.8rem'
              },
            }}
            primary={primaryText}
            secondary={user.email}
          />
        ) : (
          <em>No user details found</em>
        )}
      </StyledListItem>
    </List>
  );
}
